<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Jornal Típico</h2>
    <button
      v-if="getJornal && !getJornal.length"
      class="
        rounded
        border border-primary
        bg-primary
        py-2
        px-4
        text-white
        font-medium
        flex
      "
      @click="onEnable"
    >
      <LoadingButtonIcon v-if="loadingEnable" />
      Habilitar
    </button>
  </div>

  <div class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead class="">
          <tr>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">Día</th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">
              Cantidad horas
            </th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <tr
            v-for="(jornal, jornalInd) in getJornal"
            :key="jornalInd"
            class="whitespace-nowrap"
          >
            <td
              class="text-left text-secondary font-light px-6 py-4 text-sm pl-0"
            >
              {{ jornal.name }}
            </td>
            <td
              class="text-left text-secondary font-light px-6 py-4 text-sm pl-0"
            >
              {{ jornal.hours }}
            </td>
            <td>
              <a
                class="
                  text-primary
                  font-medium
                  cursor-pointer
                  text-right
                  hover:bg-blue-50
                  px-2
                "
                @click="
                  onOpenDrawerJornal({
                    action: 'update',
                    jornal: jornal,
                  })
                "
              >
                <p>Editar</p>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer :open="drawerJornal" @update:open="drawerJornal = $event">
    <JornalForm
      v-if="drawerJornal"
      :action="jornalFormAction"
      :jornal-selected="jornalSelected"
      @onComplete="drawerJornal = false"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import JornalForm from "./forms/Jornal";

const store = useStore();

const drawerJornal = ref(false);
const jornalFormAction = ref("update");
const jornalSelected = ref(null);
const loadingEnable = ref(false);

const getJornal = computed(() => store.getters.getJornal);

const onOpenDrawerJornal = ({ action, jornal = null }) => {
  jornalSelected.value = { ...jornal };
  drawerJornal.value = true;
  jornalFormAction.value = action;
};

const init = async () => {
  await store.dispatch("getAllJornal");
};

init();

const onEnable = async () => {
  try {
    loadingEnable.value = true;

    await store.dispatch("enableJornal");
  } catch (error) {
    console.error(error);
  } finally {
    loadingEnable.value = false;
  }
};
</script>
